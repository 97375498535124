<template>
  <div>
    <!-- <div><input @change="loadData" v-model="data.date" type="date" /></div> -->
    <div>
      <input @change="loadData" v-model="data.date" type="date" class="me-4"/>
      <select @change="loadData" v-model="data.selectedCompany">
        <option>Alle</option>
        <option v-for="company in data.companies" :key="company" v-bind:value="company">{{company}}</option>
      </select>
  </div>
    <div>
      <div class="table-responsive" id="dataList">
      </div>
    </div>
    <span v-show="!data.list?.data.length" class="text-danger fw-bold mt-3">No data<br></span>
    <span v-show="data.error" class="text-danger fw-bold mt-3">Action failed</span>
  </div>
</template>

<script>
import axios from "axios"
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'List',
  setup() {

    const store = useStore()
    const data = reactive({
      date: store.getters['App/getDateUS'],
      list: null,
      companies: [],
      selectedCompany: 'Alle',
      error: false
    })

    const loadData = () => {
      console.log('Loading list data')
      // const result = {
      //   columns: ['Firma', 'Artikel', 'Anzahl'],
      //   data: [['Test', 'prod', '10'], ['Test', 'prod', '10']]
      // }
      // data.list = result
      // buildTable(result)
      // return
      data.list = null
      document.getElementById('dataList').innerHTML = ''
      const params = new URLSearchParams()
      params.append('date', data.date)
      params.append('company', data.selectedCompany)
      axios.post('api/data/load.php', params)
      .then(response => {
        console.log(response)
        const result = response.data
        data.list = result
        if (result.data.length) buildTable(result)
        data.error = false
      })
      .catch(error => {
        console.log(error)
        data.error = true
      })
    }

    const loadCompanies = () => {
      // data.companies = ['Firma 1', 'Firma 2', 'Firma 3']
      // return
      data.companies = []
      data.selectedCompany = 'Alle'
      const params = new URLSearchParams()
      params.append('date', data.date)
      axios.post('api/data/loadCompanies.php', params)
      .then(response => {
        console.log(response)
        const result = response.data
        data.companies = result
        data.error = false
      })
      .catch(error => {
        console.log(error)
        data.error = true
      })
    }

    const buildRows = (data) => {
      let content = ''
      data.forEach(el => {
        content += '<tr>'
        for (let index = 0; index < Object.keys(el).length; index++) {
          const col = Object.values(el)[index];
          content += `<td>${col}</td>`
        }
        content += '</tr>'
      })
      return content
    }

    const buildColumns = (columns) => {
      let content = '<tr>'
      columns.forEach(el => {
        content += `<th>${el}</th>`
      })
      content += '</tr>'
      return content
    }

    const buildTable = (result) => {
      document.getElementById('dataList').innerHTML = `
      <table class="table">
        <thead>
          ${buildColumns(result.columns)}
        </thead>
        <tbody>
          ${buildRows(result.data)}
        </tbody>
      </table>`
    }

    onMounted(() => {
      loadCompanies()
      loadData()
    })

    return {
      data,
      loadData
    }
  }
}
</script>
